import { LoginMethod } from 'globals/constants';
import { BenefitType } from '@epassi/ui-commons/enums';
import { IntlLanguage } from 'i18n';

import { Country, Domain } from 'namespace/common';
import { createContext, useContext } from 'react';

export interface DomainContextProps {
  languages: IntlLanguage[];
  defaultLanguage: IntlLanguage;
  country: Country | undefined;
  benefits: BenefitType[];
  defaultBenefit: BenefitType;
  countryBounds: google.maps.LatLngBoundsLiteral;
  countryCallingCode?: string;
  domain: Domain;
  defaultLoginMethod?: LoginMethod;
}

export const DomainContext = createContext<DomainContextProps>(
  {} as DomainContextProps,
);

export const useDomainContext = (): DomainContextProps => {
  const value = useContext(DomainContext);

  if (!value) {
    throw new Error(
      'DomainContext can only be used inside the DomainContext.Provider',
    );
  }

  return value;
};
