export type RoutePathsKeys = keyof typeof RoutePaths;
export type RoutePathsValues = (typeof RoutePaths)[RoutePathsKeys];

export enum RoutePaths {
  // Admin
  ADMIN_PARTNER_INTEGRATIONS_BRP = '/admin/partner-integrations/:subjectId/brp',
  ADMIN_PARTNER_INTEGRATIONS_BRP_CREATE = '/admin/partner-integrations/:subjectId/brp/create',
  ADMIN_PARTNER_INTEGRATIONS_BRP_EDIT = '/admin/partner-integrations/:subjectId/brp/edit',
  ADMIN_FORTNOX = '/admin/fortnox',
  ADMIN_HR = '/admin/hr',
  ADMIN_HR_INTEGRATIONS = '/admin/hr/integrations',
  ADMIN_HR_CREATE_INTEGRATION = '/admin/hr/integrations/create',
  ADMIN_HR_INTEGRATION_DETAILS = '/admin/hr/integrations/:id',
  ADMIN_HR_UPDATE_INTEGRATION = '/admin/hr/integrations/:id/update',
  ADMIN_HR_SYNC_JOBS = '/admin/hr/sync-jobs',
  ADMIN_HR_SYNC_EVENTS = '/admin/hr/sync-events',
  ADMIN_HR_SFTP_ACCOUNTS = '/admin/hr/sftp-accounts',
  ADMIN_HR_CREATE_SFTP_ACCOUNT = '/admin/hr/sftp-accounts/create',
  ADMIN_HR_SFTP_ACCOUNT = '/admin/hr/sftp-accounts/:id',
  ADMIN_HR_UPDATE_SFTP_ACCOUNT = '/admin/hr/sftp-accounts/:id/update',
  ADMIN_PARTNER_INTEGRATIONS = '/admin/partner-integrations/:subjectId',
  ADMIN_RECEIPT_REQUESTS = '/admin/receipt',
  ADMIN_REPORTS = '/admin/reports',
  ADMIN_RECEIPTS_REVIEW = '/admin/receipt/:id',
  ADMIN_TABLEAU = '/admin/tableau',
  ADMIN_MERCHANT_ANALYTICS = '/admin/merchant-analytics',
  LOGIN = '/login',
  PHONE_LOGIN = '/login-phone',
  OTP = '/otp',
  OTP_PHONE = '/otp-phone',
  PIN = '/pin',
  LEGACY_LOGIN = '/legacy-login',
  TNC_CONFIRMATION = '/tnc',
  CHECK_PERSONAL_DETAILS = '/check-details',
  SELECT_BENEFIT_ACCOUNT_EDITABLE = '/select-account-edit',
  SELECT_BENEFIT_ACCOUNT_VIEW = '/select-account-view',
  SELECT_BENEFIT_ACCOUNT_OTP_EMAIL_UPDATE = '/select-account-edit/otp-email-update',
  SELECT_BENEFIT_ACCOUNT_OTP_PHONE_UPDATE = '/select-account-edit/otp-phone-update',
  USER = '/user',
  USER_OTP_EMAIL_UPDATE = '/user/otp-email-update',
  USER_OTP_PHONE_UPDATE = '/user/otp-phone-update',
  USER_EDIT_INFO = '/user/edit',
  EMAIL_LOGIN = '/email-login',
  MERCHANT_AUTHORIZE = '/authorize/:actionConsentToken',
  MERCHANT_AUTHORIZE_PHONE = '/authorize/:actionConsentToken?login=phone',
  MERCHANT_AUTHORIZE_SHOW = '/authorize-show',
  MERCHANT_ACTIVATED = '/authorize-activated',
  NEW_BENEFIT = '/new-benefit',
  E_PAYMENTS_LOGIN = '/e-payments-login',
  E_PAYMENTS_PHONE_LOGIN = '/e-payments-login?login=phone',
  E_PAYMENTS_BANK_ID_LOGIN = '/e-payments-login?login=bankId',
  E_PAYMENTS = '/e-payments',
  REPORTS = '/reports',
  KYC = '/kyc',
  KYC_MORE_INFO = '/kyc/auth/start',
  KYC_STRONG_AUTHENTICATION = '/kyc/auth/info',
  KYC_STRONG_AUTHENTICATION_SUCCESS = '/kyc/auth/end',
  KYC_FORM = '/kyc/form/start',
  KYC_ALL_DONE = '/kyc/form/done',
  KYC_FORM_ERROR = '/kyc/form/error',
  KYC_AUTH_FAILED = '/kyc/auth/failed',
  MERCHANT_SEARCH = '/merchant-search',
  MERCHANT_SITE = '/merchant-search/site/:id',
  RECEIPT_HANDLING = '/receipt',
  RECEIPT_HANDLING_BENEFIT_SOURCE_SELECTION = '/receipt/benefit-source',
  RECEIPT_HANDLING_REGISTRATION = '/receipt/register',
  RECEIPT_HANDLING_VIEW = '/receipt/view/:id',
  BANK_ID = '/login/bank-id',
  TABLEAU = '/tableau',
  BIKE = '/bike',
  BIKE_CODE = '/bike/code/show',

  // Agreement Management
  AGREEMENT_MANAGEMENT = '/agreement-management',
  AGREEMENT_MANAGEMENT_EXISTING = '/agreement-management/existing',
  AGREEMENT_MANAGEMENT_PENDING = '/agreement-management/pending',
  AGREEMENT_MANAGEMENT_SUGGEST = '/agreement-management/suggest',
  AGREEMENT_MANAGEMENT_SETTINGS = '/agreement-management/settings',

  // Company Admin
  COMPANY_ADMIN_HR = '/company-admin/hr',
  COMPANY_ADMIN_HR_SYNC_JOBS = '/company-admin/hr/sync-jobs',
  COMPANY_ADMIN_HR_SYNC_EVENTS = '/company-admin/hr/sync-events',

  //Merchant Analytics
  MERCHANT_ANALYTICS = '/merchant-analytics',
}
