import { Order } from '@epassi/ui-commons/enums';

export enum Currency {
  SEK = 'SEK',
  EUR = 'EUR',
}

export const MonthNames = [
  'month.january',
  'month.february',
  'month.march',
  'month.april',
  'month.may',
  'month.june',
  'month.july',
  'month.august',
  'month.september',
  'month.october',
  'month.november',
  'month.december',
];

export enum FeatureFlagsType {
  NOT_ENABLED_FOR_PRODSTAGING,
  MERCHANT_SEARCH_ENABLED,
  MERCHANT_SITE_ENABLED,
  RECEIPT_HANDLING_ENABLED,
  ONLINE_PAYMENTS_ENABLED_SE,
  ONLINE_PAYMENTS_ENABLED_FI,
  ONLINE_PAYMENTS_LEGAL_DOCUMENTS,
  AGREEMENT_MANAGEMENT,
  AGREEMENT_MANAGEMENT_SETTINGS,
  REPORTS_DATE_RANGE,
  REPORTS_DOWNLOAD,
  TABLEAU_ENABLED,
  BIKE_ENABLED,
  DESIGN,
  BANK_ID,
  COMPANIES,
  MERCHANT_ANALYTICS_ENABLED,
}

export enum Domain {
  FI = 'fi',
  SE = 'se',
  COM = 'com',
}

export enum Country {
  GB = 'GB',
  SE = 'SE',
  FI = 'FI',
  ES = 'ES',
  DE = 'DE',
  NO = 'NO',
  IS = 'IS',
  EE = 'EE',
  SI = 'SI',
  HU = 'HU',
  PL = 'PL',
}

export const CountrySearchKeys: Record<Country, CountryName> = {
  GB: 'United Kingdom',
  SE: 'Sweden',
  FI: 'Finland',
  ES: 'Spain',
  DE: 'Germany',
  NO: 'Norway',
  IS: 'Iceland_EUR',
  EE: 'Estonia',
  SI: 'Slovenia',
  HU: 'Hungary_EUR',
  PL: 'Poland',
} as const;

export const CURRENCY_BY_COUNTRY: Partial<
  Record<Country, Currency | undefined>
> = {
  [Country.FI]: Currency.EUR,
  [Country.SE]: Currency.SEK,
  [Country.GB]: undefined,
};

export enum Error {
  JWT_ERROR = 'JWT_ERROR',
}

export type SortKey<T extends object> = keyof T | '';

export interface Sort<K extends string = string> {
  by: K;
  order: Order;
}

export enum AccountType {
  VISITOR = 'VISITOR',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  COMPANY = 'COMPANY',
  CORPORATION = 'CORPORATION',
  SERVICE_PROVIDER = 'SERVICEPROVIDER',
  SITE = 'SITE',
  CHAIN = 'CHAIN',
  USER = 'USER',
}
