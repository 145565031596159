const AdminFortnoxEndpoints = {
  ADMIN_FORTNOX_INVOICES: '/api/admin/fortnox/v1/invoices',
  ADMIN_FORTNOX_GENERATE_NEW_TOKENS:
    '/api/admin/fortnox/v1/generate_new_tokens',
  ADMIN_FORTNOX_CHECK_STATUS: '/api/admin/fortnox/v1/check_status',
} as const;

const AmtEndpoints = {
  AMT_AGREEMENT_SETTINGS: '/api/agreement_management/v1/agreement_settings',
  AMT_AGREEMENT_SUGGESTIONS:
    '/api/agreement_management/v1/agreement_suggestions',
  AMT_BENEFITS: '/api/agreement_management/v1/benefits',
  AMT_CITIES: '/api/agreement_management/v1/cities',
  AMT_CONTACT_PERSON: '/api/agreement_management/v1/contact_person',
  AMT_MERCHANTS: '/api/agreement_management/v1/merchants',
  AMT_ACTIVITIES: '/api/agreement_management/v1/activities',
  AMT_COMPANIES: '/api/agreement_management/v1/companies',
  AMT_COUNTRY_BENEFITS_RULES: (country: string) =>
    `/api/agreement_management/v1/agreement_suggestions/country_benefit_rules/${country}` as const,
  AMT_AGREEMENT_SUGGESTIONS_APPROVE:
    '/api/agreement_management/v1/agreement_suggestions/approve',
  AMT_AGREEMENT_SUGGESTIONS_REJECT:
    '/api/agreement_management/v1/agreement_suggestions/reject',
  AMT_AGREEMENT_SUGGESTIONS_UPDATE_PRICE:
    '/api/agreement_management/v1/agreement_suggestions/update_price',
} as const;

const BikeEndpoints = {
  BIKE_CODE_DETAILS: '/api/bike/v1/code_details',
  BIKE_CODE: '/api/bike/v1/code',
  BIKE_BENEFIT: '/api/bike/v1/benefit',
  BIKE_BENEFIT_ACCEPT_TNC: '/api/bike/v1/benefit/acceptTnc',
  BIKE_CONTRACTS: '/api/bike/v1/contracts',
  BIKE_COMPANIES: '/api/bike/v1/companies',
  BIKE_COMPANIES_DETAILS: '/api/bike/v1/company_information',
  BIKE_COMPANIES_BENEFITS_ACTIVATED:
    '/api/bike/v1/active_bike_benefit_companies',
  BIKE_EMPLOYEE_SEARCH: '/api/bicycle/fetch_employee',
  BIKE_UPLOAD: (country: string) => `api/bicycle/upload/${country}` as const,
  BIKE_ERRORS: (country: string) =>
    `api/bicycle/upload/error/${country}` as const,
  BIKE_COMPANY_EXTENDED_INFORMATION:
    '/api/bike/v1/company_extended_information',
} as const;

const BrpEndpoints = {
  BRP_SERVICE_PROVIDERS: '/api/brp-adapter/v1/setup/service_providers',
  BRP_SERVICE_PROVIDER_ID: (serviceProviderId: number) =>
    `/api/brp-adapter/v1/setup/service_providers/${serviceProviderId}` as const,
  BRP_SERVICE_PROVIDER_ID_DETAILS: (serviceProviderId: number) =>
    `/api/brp-adapter/v1/setup/service_providers/${serviceProviderId}/details` as const,
  BRP_SERVICE_PROVIDER_ID_LIST_SITES: (serviceProviderId: number) =>
    `/api/brp-adapter/v1/setup/service_providers/${serviceProviderId}/list_sites` as const,
} as const;

const DataEndpoints = {
  DATA_SERVICE_PROVIDERS_ID: (id: number) =>
    `/api/data/v1/service_providers/${id}` as const,
  DATA_SERVICE_PROVIDERS_ID_SITES: (id: number) =>
    `/api/data/v1/service_providers/${id}/sites` as const,
  DATA_SERVICE_PROVIDERS_ID_AVAILABLE_SITES: (id: number) =>
    `/api/data/v1/service_providers/${id}/available_sites` as const,
  DATA_SITES_ID: (id: number) => `/api/data/v1/sites/${id}` as const,
} as const;

const DiscoveryEndpoints = {
  DISCOVERY_CATEGORIES: '/api/discovery/v1/categories',
  DISCOVERY_INFO: '/api/discovery/v1/info',
  DISCOVERY_SITESTATS: '/api/discovery/v1/sitestats',
  DISCOVERY_NEARBY: '/api/discovery/v1/nearby',
};

const ExternalPaymentEndpoints = {
  EP_ADYEN_PAYMENT_METHODS: '/api/external_payment/v1/adyen/payment_methods',
  EP_POLL_TRANSACTION_MIXED_PAYMENT: (paymentId?: string) =>
    `/api/external_payment/v1/poll/transactions/${paymentId}/mixed_payment` as const,
  EP_ADYEN_PAYMENT_DETAILS: '/api/external_payment/v1/adyen/payment_details',
  EP_ADYEN_PAYMENTS: '/api/external_payment/v1/adyen/payments',
  EP_ADYEN_PAYMENT_METHOD_ID: (storedMethodId: string) =>
    `/api/external_payment/v1/adyen/payment_methods/${storedMethodId}` as const,
} as const;

const HrIntegrationsEndpoints = {
  HR_INTEGRATIONS: '/api/hr-integration-service/v1/integrations',
  HR_INTEGRATIONS_ID: (id: number) =>
    `/api/hr-integration-service/v1/integrations/${id}` as const,
  HR_INTEGRATIONS_SYNC_JOBS:
    '/api/hr-integration-service/v1/integrations/syncjobs',
  HR_INTEGRATIONS_COMPANY_SYNC_JOBS: (company_id: number | undefined) =>
    `/api/hr-integration-service/v1/integrations/syncjobs/${company_id}` as const,
  HR_INTEGRATIONS_RESULTS:
    '/api/hr-integration-service/v1/integrations/results',
  HR_INTEGRATIONS_COMPANY_RESULTS: (company_id: number | undefined) =>
    `/api/hr-integration-service/v1/integrations/results/${company_id}` as const,
  HR_SFTP_SERVER_USERS: '/api/hr-integration-service/v1/sftp_server/users',
} as const;

const KycEndpoints = {
  KYC_DICTIONARY: '/api/kyc/v1/dictionary',
  KYC_QUESTIONNAIRE: '/api/kyc/v1/questionnaire',
} as const;

const LogoutEndpoints = {
  LOGOUT: '/api/logout/v1',
  LOGOUT_ALL: '/api/logout/v1/logout_all',
} as const;

const PomsEndpoints = {
  POMS_INTEGRATIONS_ID: (id: number) =>
    `/api/partner-order-management-service/v1/integrations/${id}` as const,
  POMS_ITEM_CODES_SYNC:
    '/api/partner-order-management-service/v1/item_codes/sync',
  POMS_ITEM_CODES_DEACTIVATE:
    '/api/partner-order-management-service/v1/item_codes/deactivate',
  POMS_ITEM_CODES_SYNC_RESULTS_ID: (id: number) =>
    `/api/partner-order-management-service/v1/item_codes/sync_results/${id}` as const,
} as const;

const ReceiptEndpoints = {
  RECEIPT: '/api/receipt/v1',
  RECEIPT_ACTIVITIES: '/api/receipt/v1/activities',
  RECEIPT_ACTIVITIES_ID: (id: number) =>
    `/api/receipt/v1/activities/${id}` as const,
  RECEIPT_BANKING_RULES: '/api/receipt/v1/banking/rules',
  RECEIPT_BANKING_USER_INFORMATION: '/api/receipt/v1/banking/user_information',
  RECEIPT_VALIDATE_BUSINESS_ID: '/api/receipt/v1/validate/business_id',
  RECEIPT_VALIDATE_PURCHASE_DATE: '/api/receipt/v1/validate/purchase_date',
  RECEIPT_VALIDATE_AMOUNT: '/api/receipt/v1/validate/amount',
  RECEIPT_USER_RECEIPTS: '/api/receipt/v1/user/receipts',
  RECEIPT_FILE: (fileName: string) =>
    `/api/receipt/v1/file/${fileName}` as const,
  RECEIPT_RESUBMISSIONS: '/api/receipt/v1/resubmissions',
  RECEIPT_RESUBMIT: '/api/receipt/v1/resubmit',
  RECEIPT_REVIEW: '/api/receipt/v1/review',
  RECEIPT_ADMIN_RECEIPTS: '/api/receipt/v1/admin/receipts',
  RECEIPT_LOCK: '/api/receipt/v1/lock',
} as const;

const ReportingEndpoints = {
  REPORTING: '/api/reporting/employer/v1',
  REPORTING_AVAILABLE_YEARS: '/api/reporting/employer/v1/available_years',
  REPORTING_WORKING_PLACES: '/api/reporting/employer/v1/working_places',
  REPORTING_DEPARTMENTS: '/api/reporting/employer/v1/departments',
  REPORTING_BENEFITS: '/api/reporting/employer/v1/benefits',
  REPORTING_UNITS: '/api/reporting/employer/v1/units',
  REPORTING_YEARLY_SUMMARY: '/api/reporting/employer/v1/yearly_summary',
  REPORTING_TOP_MERCHANTS: '/api/reporting/employer/v1/top_merchants',
  REPORTING_DOWNLOAD_SPECIFIC_EXCEL:
    '/api/reporting/employer/v1/download_specific_excel',
  REPORTING_COMPANIES: '/api/reporting/employer/v1/companies',
  REPORTING_EXCEL_OPTIONS: '/api/reporting/employer/v1/excel_options',
  REPORTING_AVAILABLE_LEVELS: '/api/reporting/employer/v1/available_levels',
} as const;

const UserEndpoints = {
  USER: '/api/user/v1',
  USER_RECEIPT_HANDLING_SOURCES: '/api/user/v1/receipt_handling_sources',
  USER_FEATURES: '/api/user/v1/features',
  USER_PREFILL_INFO: '/api/user/v1/prefill_info',
  USER_LINK_ACCOUNT: '/api/user/v1/link_account',
  USER_EMAIL_CHALLENGE: '/api/user/v1/email/challenge',
  USER_PHONE_CHALLENGE: '/api/user/v1/phone/challenge',
  USER_EMAIL_UPDATE: '/api/user/v1/email/update',
  USER_PHONE_UPDATE: '/api/user/v1/phone/update',
  USER_CHECK_YOUR_INFO_WEB: '/api/user/v1/check_your_info_web',
  USER_PHONE: '/api/user/v1/phone',
  USER_EMAIL: '/api/user/v1/email',
  USER_CHECK_YOUR_INFO_EMPLOYEE_ONLY:
    '/api/user/v1/check_your_info_employee_only',
  // Others
  USER_BALANCES_DETAILS: '/api/user/balances/v1/details',
} as const;

const UserLoginEndpoints = {
  USER_LOGIN: '/api/user/login/v1',
  USER_LOGIN_EMAIL: '/api/user/login/v1/email',
  USER_LOGIN_VERIFICATION: '/api/user/login/v1/verification',
  USER_LOGIN_EMAIL_VERIFICATION: '/api/user/login/v1/email_verification',
  USER_LOGIN_LEGACY: '/api/user/login/v1/legacy',
  USER_LOGIN_BANK_ID_INIT: '/api/user/login/v1/bank_id/init',
  USER_LOGIN_BANK_ID_CANCEL: '/api/user/login/v1/bank_id/cancel',
  USER_LOGIN_BANK_ID_CHECK: '/api/user/login/v1/bank_id/check',
  USER_LOGIN_SECRET_PROOF: '/api/user/login/v1/secret_proof',
  // Other user types
  USER_SIGN_UP: '/api/user/sign_up/v1',
  EMPLOYER_LOGIN: '/api/employer/login/v1',
  MERCHANT_LOGIN: '/api/merchant/login/v1',
} as const;

const UserPayments = {
  USER_PAYMENTS_OPTIONS: '/api/user/payments/v1/options',
  USER_PAYMENTS_OPEN_OPTIONS: '/api/user/payments/v1/open_options',
  USER_PAYMENTS_DATA_PREFILL: '/api/user/v1/payments_data_prefill',
  USER_PAYMENTS_DETAILS: '/api/user/payments/v1/details',
  USER_PAYMENTS_ONLINE_PAYMENT: '/api/user/payments/v1/online_payment',
} as const;

const ApiProperties = {
  ...AdminFortnoxEndpoints,
  ...AmtEndpoints,
  ...BikeEndpoints,
  ...BrpEndpoints,
  ...DataEndpoints,
  ...DiscoveryEndpoints,
  ...ExternalPaymentEndpoints,
  ...HrIntegrationsEndpoints,
  ...KycEndpoints,
  ...LogoutEndpoints,
  ...PomsEndpoints,
  ...ReceiptEndpoints,
  ...ReportingEndpoints,
  ...UserEndpoints,
  ...UserLoginEndpoints,
  ...UserPayments,
  // Others
  TABLEU_AUTH: '/api/tableau/auth',
  LEGAL_AGREEMENT: '/api/legal_agreement/v1',
  TOKEN_EXCHANGE: '/api/token/v1/exchange',
  SECRETS_CHALLENGE_VERIFY: '/api/secrets/v1/challenge/verify',
  ANALYTICS_REPORTS: (reportCategory: string, reportType: string) =>
    `/api/analytics/v1/reports?reportCategory=${reportCategory}&reportType=${reportType}`,
} as const;

export default ApiProperties;
