import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import IntlLocales, {
  IntlLanguage,
  IntlLocaleKey,
} from 'i18n/locales/intl-locale';
import localStorageService, { LANGUAGE_KEY } from 'local-storage/local-storage';
import { useDomainContext } from 'context/domain/domain-context';
import { Country } from 'namespace/common';
import noop from 'lodash/noop';

export type LocaleContextType = {
  locale: IntlLocaleKey;
  defaultLanguage?: IntlLanguage;
  selectLanguage: (language: IntlLanguage) => void;
};

const LocaleContext = createContext<LocaleContextType>({
  locale: IntlLocales.en_GB,
  selectLanguage: noop,
});

const useLocale = () => useContext(LocaleContext);

const useLoadLanguage = (setLang: Dispatch<SetStateAction<IntlLanguage>>) => {
  const { defaultLanguage } = useDomainContext();
  useEffect(() => {
    const tempLocalStorageLanguage = localStorageService.get(LANGUAGE_KEY);
    const localStorageLanguage =
      tempLocalStorageLanguage === 'sv_fi' || tempLocalStorageLanguage === 'se'
        ? IntlLanguage.sv
        : tempLocalStorageLanguage;

    const resolvedLanguage = (localStorageLanguage ??
      defaultLanguage ??
      navigator.language.split(/[-_]/)[0]) as IntlLanguage;
    setLang(resolvedLanguage);
  }, [defaultLanguage, setLang]);
};

const getLanguageFromQueryParams = () => {
  const queryParams = new URLSearchParams(location.search);
  const l = queryParams.get('l');
  if (!l) return null;

  if (l === 'sv_fi' || l === 'se') {
    return IntlLanguage.sv;
  }

  return IntlLanguage[l as keyof typeof IntlLanguage];
};

export const LocaleContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [language, setLanguage] = useState<IntlLanguage>(IntlLanguage.en);
  const [country, setCountry] = useState<Country>(Country.GB);
  const queryParamsLanguage = getLanguageFromQueryParams();

  useLoadLanguage(setLanguage);

  const { defaultLanguage, country: domainCountry } = useDomainContext();
  useEffect(() => {
    if (!!domainCountry) {
      setCountry(domainCountry);
    }
  }, [domainCountry]);

  const selectLanguage = useCallback(
    (lang: IntlLanguage) => {
      if ((lang as unknown) === 'sv_fi' || (lang as unknown) === 'se') {
        setLanguage(IntlLanguage.sv);
      } else {
        setLanguage(lang);
      }
      localStorageService.set(LANGUAGE_KEY, lang);
    },
    [setLanguage],
  );

  const value = useMemo<LocaleContextType>(
    () => ({
      locale: new IntlLocaleKey(queryParamsLanguage || language, country),
      selectLanguage,
      defaultLanguage,
    }),
    [queryParamsLanguage, language, country, selectLanguage, defaultLanguage],
  );

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export default useLocale;
